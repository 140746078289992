<div id="bade-footer">
  <h2 class="first">Partnernetzwerk</h2>
  <a href="https://www.berlin.de/lageso/"
    ><img
      src="/assets/images/partner/lageso@2x.png"
      width="121"
      alt="Landesamt für Gesundheit und Soziales Berlin"
      style="margin-right:60px;"
    /></a
  >
  <a href="http://www.bwb.de/"
    ><img
      src="/assets/images/partner/bwb@2x.png"
      width="178"
      alt="Berliner Wasserbetriebe"
      style="margin-right:35px;"
    /></a
  >
  <a href="http://www.kompetenz-wasser.de/de/"
    ><img
      src="/assets/images/partner/kwb@2x.png"
      width="168"
      alt="Kompetenzzentrum Wasser Berlin"
      style="margin-right:40px;"
    /></a
  >
  <a href="https://technologiestiftung-berlin.de/"
    ><img
      src="/assets/images/partner/tsb@2x.png"
      width="147"
      alt="Technologiestiftung Berlin"
    /></a
  >
  <h2>Entwickelt im Projekt</h2>
  <a href="http://www.kompetenz-wasser.de/de/project/flusshygiene/"
    ><img
      src="/assets/images/partner/flusshygiene@2x.png"
      width="150"
      alt="Flusshygiene"
      style="margin-right:0;"
    /></a
  >
  <h2>Gefördert durch</h2>
  <a href="https://www.bmbf.de/"
    ><img
      src="/assets/images/partner/bmbf@2x.png"
      width="102"
      alt="BMBF"
      style="margin-right:40px;"
    /></a
  >
  <a href="https://bmbf.nawam-rewam.de/"
    ><img
      src="/assets/images/partner/ReWaM_NaWaM@2x.png"
      width="233"
      alt="ReWaM NaWaM"
    /></a
  ><br /><br />
</div>
