<script lang="ts">
  import * as animateScroll from 'svelte-scrollto'
  animateScroll.scrollTo(0)
</script>

<h2>Impressum</h2>

<h3>Herausgeber</h3>
<p class="lageso">Landesamt für Gesundheit und Soziales Berlin (LAGeSo)<br>Turmstr. 21, Haus A<br>10559 Berlin</p>
<p class="lageso">Telefon: (030) 90229-0<br>Telefax: (030) 90229-1099</p>
<p class="lageso"><a href="mailto:poststelle@lageso.berlin.de" title="E-Mail an die Poststelle des LAGeSo">E-Mail</a><br>(nicht für Dokumente mit elektronischer Signatur)</p>
<p class="lageso">
  Postanschrift<br>
  Postfach 31 09 29<br>
  10639 Berlin
</p>
<h4>
  Vertretungsberechtigt
</h4>
<p class="lageso">
  Präsident: Herr Straßmeir
</p>
<h4>
  Inhaltliche Verantwortung
</h4>
<p class="lageso">
  Silvia Kostner – Z Press -<br>
  <a href="mailto:presse@lageso.berlin.de" title="E-Mail an die Pressestelle des LAGeSo">E-Mail</a><br>
  (nicht für Dokumente mit elektronischer Signatur)
</p>
<h4>
  Social Media
</h4>
<p class="lageso">Benjamin Ciupek – Z Press 1 – <a href="mailto:socialmedia@lageso.berlin.de" title="E-Mail an Social Media">E-Mail</a> (nicht für Dokumente mit elektronischer Signatur)</p>
<ul class="lageso">
  <li>
    <a href="https://www.berlin.de/lageso/ueber-uns/artikel.714512.php" title="Weitere Informationen">Weitere Informationen</a>
  </li>
</ul>
<h4>
  Webmaster
</h4>
<p  class="lageso">
  Antje Weiß – ZS B 31 -<br>
  Detlef Köppel – ZS B 33 -<br>
  <a href="mailto:webmaster@lageso.berlin.de" title="E-Mail an die Webmaster des LAGeSo">E-Mail</a><br>
  (nicht für Dokumente mit elektronischer Signatur)
</p>
<h3>
  Zentrales E-Mail-Postfach für Dokumente mit elektronischer Signatur
</h3>
<p  class="lageso">
  Das Landesamt für Gesundheit und Soziales verfügt über ein zentrales E-Mail-Postfach, an das auch Dokumente verschickt werden können, die eine <a href="https://www.berlin.de/sen/inneres/moderne-verwaltung/e-government/vertrauensdienste/signatur/artikel.232354.php" title="Weitere Informationen zur qualifizierten elektronischen Signatur">qualifizierte elektronische Signatur</a> haben. Grundlage hierfür ist § 3 a Abs. 1 Verwaltungsverfahrensgesetz.
</p>
<p  class="lageso">
  E-Mail: <a href="mailto:post@lageso.berlin.de" title="Link zum Postfach: post@lageso.berlin.de">post@lageso.berlin.de</a><br>
  Eine wirksame Übermittlung verschlüsselter Dateien ist gegenwärtig ausgeschlossen.
</p>
<h4>
  DE Mail
</h4>
<p  class="lageso">
  post@lageso-berlin.de-mail.de
</p>
<h3>
  Benutzerhinweise und Barrierefreiheit
</h3>
<p  class="lageso">
  <a href="https://www.berlin.de/rbmskzl/service/stadtinformationssystem-berlin-de/artikel.123149.php" title="Link zu den Hinweisen für Berlin.de-Nutzer zur Bedienung der Seiten und zur Barrierefreiheit">Hinweise für Berlin.de-Nutzer zur Bedienung der Seiten zur Barrierefreiheit</a>
</p>
<h3>
  Technischer Betrieb
</h3>
<p  class="lageso">
  <a href="http://www.technologiestiftung-berlin.de/" class=" extern" target="_blank" title="Link zum Auftritt der gemeinnützigen Technologiestiftung Berlin (Öffnet in neuem Fenster)">Technologiestiftung Berlin</a><br>
  Grunewaldstraße 61-62<br />
  10825 Berlin<br />
  Tel.: +49 30 209 69 99-0<br />
  E-Mail: <a href="info@technologiestiftung-berlin.de" title="Link zum Postfach:info@technologiestiftung-berlin">info@technologiestiftung-berlin</a>
</p>
<h4>
  Haftungshinweis
</h4>
<p  class="lageso">
  Alle Angaben erfolgen ohne Gewähr. Eine Haftung für Schäden, die sich aus der Verwendung der abgerufenen Informationen und Dienste ergeben, durch das Land Berlin beziehungsweise die einstellenden Institutionen ist ausgeschlossen.
</p>
<p  class="lageso">
  Bei “Links” handelt es sich allerdings stets um “lebende” (dynamische) Verweisungen. Das Landesamt für Gesundheit und Soziales Berlin hat bei der erstmaligen Verknüpfung zwar den fremden Inhalt daraufhin überprüft, ob durch ihn eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. Es ist aber nicht dazu verpflichtet, die Inhalte, auf die es in seinem Angebot verweist, ständig auf Veränderungen zu überprüfen, die eine Verantwortlichkeit neu begründen könnten.
</p>
<h4>
  Urheberrecht
</h4>
<p  class="lageso">
  Layout und Gestaltung des Angebotes insgesamt sowie einzelne Elemente sind urheberrechtlich geschützt. Gleiches gilt auch für die inhaltlichen Beiträge. Veränderungen daran dürfen nicht vorgenommen werden, Weiterverwendung und Vervielfältigung sind nur zu privaten Zwecken gestattet. Eine öffentliche Verwendung des Angebots darf nur mit Zustimmung des Landesamtes für Gesundheit und Soziales erfolgen.
</p>
