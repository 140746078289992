<!-- START: LANDESFOOTER -->
<footer id="footer" class="kiekma">
  <!-- portalfooter logo -->
  <div class="footer__brand">
  <div class="image__image" style="">
  <a href="https://www.berlin.de/" title="Link führt zur Startseite von Berlin.de">        
    <img src="//www.berlin.de/i9f/r1/images/logo_berlin_m_negativ.svg" alt="Link zu: Link führt zur Startseite von Berlin.de" loading="lazy" data-orig="/i9f/r1/images/logo_berlin_m_negativ.svg"/>
  </a>
  </div>
  </div>
  <!-- portalfooter links -->
  <nav class="footer__links" aria-label="Weitere Bereiche auf Berlin.de">
  <ul class="js-collapse-palm">
  <li>
    <!--FLEX BEGIN: Footerlinklist-->
    <h3 class="heading js-trigger">Service</h3>
    <ul>
      <li><a href="https://service.berlin.de/onlineverfahren-onlinedienstleistungen/" >Online-Dienstleistungen</a></li>
      <li><a href="https://service.berlin.de/app/" >Service-App</a></li>
      <li><a href="https://service.berlin.de/terminvereinbarung/" >Termin vereinbaren</a></li>
      <li><a href="https://service.berlin.de/buergertelefon/" >Bürgertelefon 115</a></li>
      <li><a href="https://www.berlin.de/polizei/service/so-erreichen-sie-uns/artikel.532842.php" >Notdienste</a></li>
      <li><a href="https://www.berlin.de/ea/" >Gewerbeservice</a></li>
    </ul>
    <!--FLEX END: Footerlinklist-->
  </li>
  <li>
    <!--FLEX BEGIN: Footerlinklist-->
    <h3 class="heading js-trigger">Behörden</h3>
    <ul>
      <li><a href="https://service.berlin.de/behoerden/" >Behörden A-Z</a></li>
      <li><a href="https://service.berlin.de/senatsverwaltungen/" >Senatsverwaltungen</a></li>
      <li><a href="https://service.berlin.de/bezirksaemter/" >Bezirksämter</a></li>
      <li><a href="https://service.berlin.de/standorte/buergeraemter/" >Bürgerämter</a></li>
      <li><a href="https://service.berlin.de/jobcenter/" >Jobcenter</a></li>
      <li><a href="https://www.berlin.de/einwanderung/" >Einwanderungsamt</a></li>
    </ul>
    <!--FLEX END: Footerlinklist-->
  </li>
  <li>
    <!--FLEX BEGIN: Footerlinklist-->
    <h3 class="heading js-trigger">Politik & Verwaltung</h3>
    <ul>
      <li><a href="https://www.berlin.de/rbmskzl/regierender-buergermeister/senat/" >Landesregierung</a></li>
      <li><a href="https://www.berlin.de/karriereportal/" >Karriere im Land Berlin</a></li>
      <li><a href="https://mein.berlin.de" target="_blank" title=" (Öffnet in neuem Fenster) (Öffnet in neuem Fenster)">Bürgerbeteiligung</a></li>
      <li><a href="https://daten.berlin.de" target="_blank" title=" (Öffnet in neuem Fenster) (Öffnet in neuem Fenster)">Open Data</a></li>
      <li><a href="https://www.berlin.de/vergabeplattform/" >Vergaben</a></li><li><a href="https://www.berlin.de/buergeraktiv/" >Ehrenamt</a></li>
    </ul>
    <!--FLEX END: Footerlinklist-->
  </li>
  <li>
    <!--FLEX BEGIN: Footerlinklist-->
    <h3 class="heading js-trigger">Aktuelles</h3>
    <ul>
      <li><a href="https://www.berlin.de/corona/" >Covid-19</a></li>
      <li><a href="https://www.berlin.de/presse/" >Pressemitteilungen</a></li>
      <li><a href="https://www.berlin.de/polizei/polizeimeldungen/" >Polizeimeldungen</a></li>
      <li><a href="https://www.berlin.de/land/kalender/" >Veranstaltungen</a></li>
    </ul>
    <!--FLEX END: Footerlinklist-->
  </li>
  <li>
    <!--FLEX BEGIN: Footerlinklist-->
    <h3 class="heading js-trigger">Themen</h3><ul>
      <li><a href="https://mietendeckel.berlin.de/" target="_blank" title=" (Öffnet in neuem Fenster) (Öffnet in neuem Fenster)">Mietendeckel</a></li>
      <li><a href="https://www.berlin.de/sen/uvk/verkehr/mobilitaetswende/verkehr-888873.php" >Mobilitätswende</a></li>
      <li><a href="https://www.berlin.de/moderne-verwaltung/" >Moderne Verwaltung</a></li>
      <li><a href="https://www.berlin.de/mauer/" >Berliner Mauer</a></li>
      <li><a href="https://www.berlin.de/berlin-im-ueberblick/" >Berlin im Überblick</a></li>
    </ul>
    <!--FLEX END: Footerlinklist-->
  </li>
  <li>
    <!--FLEX BEGIN: Footerlinklist-->
    <h3 class="heading js-trigger">Weitere Informationen</h3>
    <ul>
      <li><a href="https://www.berlin.de/kultur-und-tickets/" >Kultur &#38; Ausgehen</a></li>
      <li><a href="https://www.berlin.de/tourismus/" >Tourismus</a></li>
      <li><a href="https://www.berlin.de/wirtschaft/" >Wirtschaft</a></li>
      <li><a href="https://www.berlin.de/special/" >Lifestyle</a></li>
      <li><a href="https://www.berlin.de/adressen/" >BerlinFinder</a></li>
      <li><a href="https://www.berlin.de/stadtplan/" >Stadtplan</a></li>
    </ul>
    <!--FLEX END: Footerlinklist-->
  </li>
  </ul>
  </nav>

  <div class="footer__claim">
  <p>badestellen.berlin.de ist ein Angebot des Landesamtes für Gesundheit und Soziales Berlin. Weitere Informationen hierzu finden Sie im <a href="https://www.berlin.de/lageso/ueber-uns/artikel.61719.php">Impressum</a>.        </p>
  </div>
  </footer>
  <!-- END: /LANDESFOOTER -->