<div id="legend-container">
  <div id="legend">
    <ul>
      <li><img src="/assets/images/legend_gruen@2x.png" alt="" />Zum Baden geeignet</li>
      <li>
        <img src="/assets/images/legend_orange@2x.png" alt="" />Vom Baden wird abgeraten
      </li>
      <li><img src="/assets/images/legend_rot@2x.png" alt="" />Badeverbot</li>
    </ul>
  </div>
  <div id="measure">
    Basierend auf den letzten<br class="responsive-break" />
    Messungen des LAGeSo.<br class="responsive-break" />
    <a
      href="https://www.berlin.de/lageso/gesundheit/gesundheitsschutz/badegewaesser/badegewaesserueberwachung/"
      >Mehr&nbsp;erfahren&nbsp;»</a
    >
  </div>
  <hr class="closer" />
</div>
