<div id="splash">
  <div>
    <img
      src="assets/images/splash@2x.gif"
      width="53"
      height="53"
      alt="Berliner Badestellen werden geladen..."
    /><br />
    <strong>Berliner Badestellen</strong><br />
    <span class="small">werden geladen...</span>
  </div>
</div>
