<script lang="ts">
  import * as animateScroll from 'svelte-scrollto'
  animateScroll.scrollTo(0)
</script>

<h2>Datenschutzerklärung</h2>



                
<h3 id="verantwortlich">Verantwortliche/r</h3>
<p class="lageso">Verantwortliche/r im Sinne der Datenschutz-Grundverordnung (DSGVO) und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:</p>
<p class="lageso">
  <strong class="title">Landesamt für Gesundheit und Soziales</strong><br />
  Anschrift:<br />
  Postfach 31 09 29<br>
  10639 Berlin
</p>

<h3>Name und Anschrift des DSB (Datenschutzbeauftragten)</h3>
<p class="lageso">Der/die Datenschutzbeauftragte des Verantwortlichen ist:</p>
<p class="lageso">
  <strong class="title">Sabine Ave</strong><br />
  E-Mail: <a href="mailto:datenschutz@lageso.berlin.de">datenschutz@lageso.berlin.de</a><br />
  Anschrift:<br />
  Sächsische Str. 28<br>
  10707 Berlin<br>
  Raum 157
</p>


<h3 class="datenschutz-title" id="berlinde">Zentrale Dienste des Webportals</h3>
<p class="lageso">Die Seite badestellen.berlin.de wird von der Technologiestiftung Berlin im Auftrag des Landesamtes für Gesundheit und Soziales betrieben.</p>
<p class="lageso">Für den technischen Betrieb dieser Seite und alle damit einhergehenden Datenverarbeitungsvorgänge, insbesondere</p>
<ul class="lageso">
<li>Bereitstellung der Website und Erstellung von Logfiles</li>
<li>Verwendung von Cookies</li>
<li>Webanalyse/Statistik</li>
<li>Benutzung von Karten</li>
</ul>
<p class="lageso">ist Verantwortliche/r im Sinne der Datenschutz-Grundverordnung (DSGVO) und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen die:</p>
<p class="lageso">
  <a href="http://www.technologiestiftung-berlin.de/" class=" extern" target="_blank" title="Link zum Auftritt der gemeinnützigen Technologiestiftung Berlin (Öffnet in neuem Fenster)">Technologiestiftung Berlin</a><br>
  Grunewaldstraße 61-62<br />
  10825 Berlin<br />
  Tel.: +49 30 209 69 99-0<br />
  E-Mail: <a href="info@technologiestiftung-berlin.de" title="Link zum Postfach:info@technologiestiftung-berlin">info@technologiestiftung-berlin</a>
</p>
<p class="lageso">Der Datenschutzbeauftragte der Technologiestiftung Berlin ist wie folgt erreichbar: </p>
<p class="lageso"><a href="http://www.technologiestiftung-berlin.de/" class=" extern" target="_blank" title="Link zum Auftritt der gemeinnützigen Technologiestiftung Berlin (Öffnet in neuem Fenster)">Technologiestiftung Berlin</a><br>
  Grunewaldstraße 61-62<br />
  10825 Berlin<br />
  Tel.: +49 30 209 69 99-0<br />
  E-Mail: <a href="info@technologiestiftung-berlin.de" title="Link zum Postfach:info@technologiestiftung-berlin">info@technologiestiftung-berlin</a>
</p>
<p class="lageso">Die Datenschutzerklärung der Technologiestiftung Berlin finden Sie hier:</p>
<p class="lageso"><a href="https://www.technologiestiftung-berlin.de/de/datenschutz/">Datenschutzerklärung der Technologiestiftung Berlin</a></p>
<p class="lageso">Diese enthält wichtige Informationen zum Datenschutz betreffend die Betreiberseiten und den technischen Betrieb dieses Portals.</p>

<h3 id="dsb-process">Weitere datenverarbeitende Prozesse</h3>
<h4 class="datenschutz-title" id="cookies">Cookies und Webanalyse</h4>

<p class="lageso">Der technische Betreiber von badestellen.berlin.de, die Technologiestiftung Berlin stellt die Webseite bereit und erhebt dabei Browserdaten (sogenannte Logfiles). Darüber hinaus kommen auch sogenannte Cookies und Webtracking zum Einsatz. Genauere Informationen hierzu und Informationen zum Abschalten der Cookies entnehmen Sie bitte der Datenschutzerklärung der Technologiestiftung Berlin, welche Sie hier finden:</p>
<p class="lageso"><a href="https://www.technologiestiftung-berlin.de/de/datenschutz/">Datenschutzerklärung der Technologiestiftung Berlin</a></p>

<h4>Kartendarstellung</h4>
<p class="lageso">
  Für die Darstellung der Hintergrundkarte wird der Kartenanbieter Mapbox genutzt. <a
    href="https://www.mapbox.com/legal/dpa/%22%3Eunterworfen%3C/a"
    target="_blank">Mapbox</a
  >
  hat sich dem EU-US-Datenschutzschild angeschlossen. Durch die Nutzung der Mapbox Tiles
  API können Informationen über die Benutzung dieser Website an Mapbox übertragen werden.
  Die Karte wird von Ihrem Browser direkt vom Mapbox-Server abgerufen, entsprechend haben
  wir keinen Einfluss auf die von Mapbox erhobenen Daten. Wenn Sie dies nicht wünschen,
  müssen Sie JavaScript in Ihren Browsereinstellungen deaktivieren. Dies verhindert
  allerdings auch die korrekte Darstellung der Website.<br /><a
    href="https://www.mapbox.com/privacy"
    target="_blank">Datenschutzbestimmung Mapbox</a
  >
</p>